<template>
  <div class="bills-content">
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h5 mb-0"><font-awesome-icon icon="money-check" class="mt-1 mr-1 font-size-24"/>
        {{ $t('common.buy') }}
      </span>
      </div>
      <!-- First row -->
      <div class="d-flex mb-3">
        <!-- Select project -->
        <div>
          <a-select v-model="projectId" placeholder="Project" class="width-150" @change="updateTable">
            <a-select-option :value="0">
              [{{ $t('pre.all') }} {{ $t('common.projects') }}]
            </a-select-option>
            <a-select-option v-for="project in activeProjectList" :key="project.id">
              <a-badge status="success" v-if="project.is_active"/>
              <a-badge color="grey" v-else/>
              {{ project.name }}
            </a-select-option>
          </a-select>
        </div>
        <!-- Select project -->
        <!-- Select agent -->
        <div class="ml-3">
          <agent-dropdown v-model="accountId" @input="updateTable" />
        </div>
        <!-- Select agent -->
        <!-- Select by recipients, accepted -->
        <div class="ml-3">
          <a-select v-model="visibility" class="width-150"
                    @change="updateTable">
            <a-select-option :value="0">
              [All Orders]
            </a-select-option>
            <a-select-option value="accepted_only">
              <a-icon type="user-add" /> All Accepted
            </a-select-option>
            <a-select-option value="unaccepted_only">
              <a-icon type="team" /> All Unaccepted
            </a-select-option>
            <a-select-option value="no_recipients_only">
              <a-icon type="frown" /> No agents only
            </a-select-option>
          </a-select>
        </div>
        <!-- Select by recipients, accepted -->
        <!-- Select date -->
        <div class="ml-3">
          <a-range-picker
            v-model="datetime"
            format="YYYY-MM-DD"
            :allowClear="false"
            @change="updateTable"
          />
        </div>
        <!-- Select date -->
        <!-- Select search -->
        <div class="pl-2 ml-auto">
          <a-input-search
            v-model="searchValue"
            :placeholder="$t('placeholders.selectSearch')"
            style="width: 300px"
            allowClear
            @search="searchByInput"
          >
          </a-input-search>
        </div>
        <!-- Select search -->
      </div>
      <!-- First row -->
      <!-- Second row -->
      <div class="mt-1 mb-3">
        <a-row :gutter="16" type="flex" align="bottom">
          <!-- Statistics -->
          <a-col :span="6" v-if="statData.conversion" class="d-flex">
            <a-statistic :title="`${$t('common.total')}`" :value="statData.conversion.counters.p2p_buy_total[0].value"
                         style="margin-right: 2rem">
            </a-statistic>
            <div class="d-inline mt-3">
              <div>
                <a-tooltip title="Agent found Rate" placement="bottom">
                  <a-icon type="user-add" class="text-gray-5" />
                  <span class="font-weight-bolder ml-1">{{ statData.conversion.counters.p2p_buy_has_agent[0].value }}</span>
                  ({{ statData.conversion.values.has_agent_rate[0].value }}%)
                </a-tooltip>
              </div>
              <div>
                <a-tooltip title="Success Rate" placement="bottom">
                  <a-icon type="check" class="text-success" />
                  <span class="font-weight-bolder ml-1">{{ statData.conversion.counters.p2p_buy_confirmed[0].value }}</span>
                  ({{ statData.conversion.values.succeeded_rate[0].value }}%)
                </a-tooltip>
              </div>
            </div>
          </a-col>
          <a-col class="d-flex">
            <a-statistic :title="`${$t('common.sum')} USD`" :value="statData.sum_usd ? Number(statData.sum_usd).toFixed(2) : 0"
                         prefix="$"
                         style="margin-right: 50px">
            </a-statistic>
            <a-statistic :title="`${$t('common.sum')} USD A`" :value="statData.sum_usd_a ? Number(statData.sum_usd_a).toFixed(2) : 0"
                         prefix="$"
                         style="margin-right: 50px">
            </a-statistic>
            <a-statistic :title="`${$t('common.sum')}`" :value="amountOfCurrencies"
                         style="margin-right: 50px">
            </a-statistic>
          </a-col>
<!--          <a-col :span="5">-->

<!--          </a-col>-->
          <!-- Statistics -->

          <a-col style="margin-left: auto;" display="flex">
            <!-- MakeOrder button -->
            <a-button v-if="userCanEdit(user, 'payments')" @click="makeNewOrder"
                      type="primary">
<!--              <a-icon type="file-excel"/>-->
              <i class="fe fe-credit-card pr-2" />
              <span>New order</span>
            </a-button>
            <!-- MakeOrder button -->
            <!-- Export button -->
            <a-dropdown>
              <a-menu slot="overlay">
                <a-menu-item key="1" @click="createReportCSV">
                  Full Export
                </a-menu-item>
                <a-menu-item key="2" @click="openCustomReportCSVModal">
                  Custom Export
                </a-menu-item>
              </a-menu>
              <a-button
                type="primary"
                class="ml-2"
                icon="file-excel"
                :loading="reportCSVLoading"
                :disabled="reportCSVLoading"
              />
            </a-dropdown>
            <!-- Export button -->
            <!-- Reload button -->
            <a-button :loading="paymentsTableLoading" @click="refreshList" class="ml-2" icon="reload" />
            <!-- Reload button -->
          </a-col>
        </a-row>
      </div>
      <!-- Second row -->
      <a-table
        :columns="paymentsColumns"
        :dataSource="paymentsData"
        :row-key="record => record.id"
        :pagination="pagination"
        :loading="paymentsTableLoading"
        @change="handleTableChange"
      >
        <template v-slot:id="id, record">
          <text-clipboard :text="id" />
          <text-clipboard
            v-if="record.msid"
            :text="record.msid"
            class="mt-1"
            is-small
            is-grey
          />
          <text-clipboard
            v-if="record.cascade_operation?.tx_id"
            :text="record.cascade_operation.tx_id"
            class="mt-1"
            is-small
            is-grey
          />
          <time-stamp class="mt-1" :date="record.created_at" inline />
        </template>
        <template v-slot:status="status, record">
          <!--          {{ status }}-->
          <a-tooltip
            :title="`(${record.chat.msg_count}) ${$t('common.messages')}${$t('pre.in')}${$t('common.chat')}`"
            placement="bottom" v-if="record.chat && record.chat.msg_count">
            <!--            <i class="fe fe-message-circle font-size-18 text-primary" />-->
            <a href="javascript:" @click="openChatModal(record.chat)" class="mr-2">
              <a-icon type="wechat" class="text-primary font-size-18"/>
              <sup class="">{{ record.chat.msg_count }}</sup></a>
          </a-tooltip>
          <span class="mr-2" v-if="record.confirmed_at">
            <a-tooltip v-if="record.completed_webhook && record.destination.type === 'external'"
                       :title="$t('tooltips.projectPurchaseNotified')" placement="bottom">
              <a-icon type="api" class="text-primary font-size-18"/>
            </a-tooltip>
            <span v-else>
              <a-tooltip v-if="record.destination.type !== 'internal'"
                         :title="$t('tooltips.projectPurchaseNotNotified')" placement="bottom">
                <a-icon type="api" class="text-gray-4 font-size-18"/>
              </a-tooltip>
            </span>
          </span>
          <span v-if="record.cascade_operation" class="mr-2">
            <a-tooltip :title="`Through cascade transaction\nLast status: ${record.cascade_operation.status}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <a-icon type="block" class="text-primary font-size-18"/>
            </a-tooltip>
          </span>
          <order-status
            :status="status"
            :title="getOrderStatusTitle(status, record)"
          />
          <a-dropdown :trigger="['click']" class="ml-2">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <a-icon type="bars"/>
            </a>
            <a-menu slot="overlay">
              <a-menu-item key="11"
                           v-if="userCanEdit(user, 'payments') && !(record.accepted_at === null) && !(['CONFIRMED', 'ACTIVATED', 'COMPLETED'].includes(record.status))">
                <a-popconfirm
                  :title="`${$t('popups.sureToConfirmPayment')} #${record.id}?`"
                  @confirm="confirmPayment(record.id)"
                >
                  <a href="javascript:">
                    <a-icon type="check" class="text-success"/>
                    Confirm payment</a>
                </a-popconfirm>
              </a-menu-item>
              <a-menu-divider
                v-if="userCanView(user, 'payment.webhooks') || userCanView(user, 'payment.history')"/>
              <a-menu-item key="33"
                           v-if="userCanView(user, 'payment.webhooks') && record.destination && record.destination.type === 'external'">
                <a href="javascript:" @click="showWebhooks(record.id)">
                  <a-icon type="bell"/>
                  Webhooks</a>
              </a-menu-item>
              <a-menu-item key="66" v-if="userCanView(user, 'payment.history')">
                <a href="javascript:" @click="showStatusHistory(record.id)">
                  <a-icon type="build"/>
                  Status Timeline</a>
              </a-menu-item>
              <!--              <a-menu-divider />-->
            </a-menu>
          </a-dropdown>
          <span v-if="paymentsLoading" class="ml-2"><a-icon type="loading"/></span>
        </template>
        <template v-slot:account="account, record">
          <span v-if="account">
            <span class="mr-1">
              <agent-avatar :agent="account"/>
            </span>
            {{ account.name }}
            <span v-if="account.cascade_provider" class="d-block mt-1 small text-gray-5">
              <a-icon type="block" /> Provider: <span class="font-weight-bold">{{ account.cascade_provider.name }}</span>
            </span>
          </span>
          <span v-else-if="record.recipient_count > 0">-</span>
          <div class="order-recipients mt-1">
            <span v-if="record.recipient_count > 0">
              <a-tooltip title="Recipients" placement="bottom">
<!--                <a-tag>-->
                  <a href="javascript:" @click="showPaymentRecipients(record.id)" class="text-gray-5 small">
                    <a-icon type="team" />
                    {{ record.recipient_count }}
                  </a>
<!--                </a-tag>-->
              </a-tooltip>
            </span>
            <span v-else>
              <a-tag>
                <span class="text-warning"><a-icon type="warning" /></span> NO AGENTS
              </a-tag>
              <span class="d-block">
                <a-tooltip v-if="record.last_error" title="Click to open description" placement="bottom" class="text-gray-5">
                  <a href="javascript:" @click="showPaymentError(record.id, record.last_error)" class="small">
                    <span v-if="record.last_error.code === 10030"><a-icon type="user" /> No agents</span>
                    <span v-if="record.last_error.code === 10033"><a-icon type="column-width" /> Wrong range</span>
                    <span v-if="record.last_error.code === 10034"><a-icon type="column-width" /> Wrong sub-range</span>
                  </a>
                </a-tooltip>
              </span>
            </span>
          </div>
        </template>
        <template v-slot:requisites="requisites, record">
          <div v-if="requisites">
            <span v-if="record.psystem" class="small">
              <img :src="record.psystem.img_path" width="18" height="18"/> {{ record.psystem.name }}
            </span>
            <span class="d-block small text-gray-5">{{ requisites.address }}</span>
          </div>
          <span v-else>-</span>
        </template>
<!--        <template v-slot:created_at="created_at">-->
<!--          {{ $moment(created_at).format('DD.MM.YY') }}-->
<!--          <span class="small d-block text-gray-5"><a-icon-->
<!--            type="clock-circle"/> {{ $moment(created_at).format('HH:mm') }}</span>-->
<!--        </template>-->
        <template v-slot:client="client">
          <p class="mb-0">{{ client.login }}</p>
          <p class="mb-0 d-block small text-gray-5">id: {{ client.id }}</p>
          <span v-if="client.short_name" class="mt-1 d-block small text-gray-5">
            {{ client.short_name }}
          </span>
        </template>
        <template v-slot:amount="amount, record" >
          <div class="amount-content d-inline-flex">
            <div>
              <span class="font-weight-bolder">
                {{ amount }} {{ record.currency.abbr }}
              </span>
              <div class="small">
                <a-tooltip :title="`Client rate: ${record.rate}`" placement="bottom">
                  <span class="d-block text-gray-5">{{ record.amount_usd }} $</span>
                </a-tooltip>
                <a-tooltip :title="`Agent rate: ${record.rate_a}`" placement="bottom">
                  <span class="d-block text-gray-5">{{ record.amount_usd_a }} $ <agent-avatar :agent="record.account" :icon-size="12" /></span>
                </a-tooltip>
                <a-tooltip v-if="record.cascade_operation" :title="`Cascade rate: ${record.cascade_operation.rate}`" placement="bottom">
                  <span class="d-block text-gray-5">{{ record.cascade_operation.amount_out }} $ <a-icon type="block" class="text-primary" /></span>
                </a-tooltip>
              </div>
            </div>
            <div v-if="record.amount_origin !== amount" class="strike text-gray-5">
              <span class="font-weight-bolder">
                {{ record.amount_origin }} {{ record.currency.abbr }}
              </span>
            </div>
          </div>
        </template>
        <template v-slot:agent_time="agent_time">
          <span v-if="agent_time" class="small">
            {{ agent_time }}<span class="ml-1">min.</span>
          </span>
        </template>
      </a-table>
    </a-card>
    <a-modal v-model="paymentChatModal"
             :destroyOnClose="true"
             title="Client-Agent Chat"
             :footer="null"
             class="chat-modal"
             width="680px"
             height="680px"
    >
      <agent-chat-modal
        :chat-obj="paymentChat"
      />
    </a-modal>
    <a-modal v-model="paymentWebhooksModal"
             :destroyOnClose="true"
             :title="currentPaymentId ? `Payment ${currentPaymentId} webhooks` : 'Payment webhooks'"
             :footer="null"
             class="webhooks-modal"
             width="1000px"
             height="480px"
    >
      <webhooks-modal
        :payment-id="currentPaymentId"
      />
    </a-modal>
    <a-modal v-model="paymentStatusHistoryModal"
             :destroyOnClose="true"
             :title="currentPaymentId ? `Payment ${currentPaymentId} status timeline` : 'Payment status timeline'"
             :footer="null"
             class="history-modal"
             width="480px"
             height="520px"
    >
      <payment-status-history-modal
        :payment-id="currentPaymentId"
      />
    </a-modal>
    <a-modal v-model="paymentErrorModal"
             :destroyOnClose="true"
             :title="currentPaymentId ? `Payment ${currentPaymentId} last error` : 'Payment last error'"
             :footer="null"
             class="error-modal"
             width="480px"
             height="520px"
    >
      <payment-error-modal
        :payment-id="currentPaymentId"
        :error="currentPaymentError"
      />
    </a-modal>
    <a-modal v-model="paymentRecipientsModal"
             :destroyOnClose="true"
             :title="currentPaymentId ? `Payment ${currentPaymentId} recipients` : 'Payment recipients'"
             :footer="null"
             class="recipients-modal"
             width="480px"
             height="520px"
    >
      <payment-recipients-modal
        :payment-id="currentPaymentId"
      />
    </a-modal>
    <a-modal
      v-model="isOpenReportCSVModal"
      :destroyOnClose="true"
      title="BUY Orders | Export data"
      :footer="null"
      width="540px"
    >
      <export-c-s-v-modal
        :project-list="activeProjectList"
        @close="closeCustomReportCSVModal"
      />
    </a-modal>
    <new-order-modal
      :visible="makeNewOrderModal"
      @update:visible="makeNewOrderModal = $event"
      @submitFunction="updateTable"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment-timezone'
import accessMix from '@/services/accessmix.js'
import agentChatModal from '@/components/client-agent-chat/AgentChatModal.vue'
import agentDropdown from '@/components/agent/AgentDropdown.vue'
import agentAvatar from '@/components/agent/AgentAvatar.vue'
import webhooksModal from '@/views/bills/paymentWebhooksModal.vue'
import paymentStatusHistoryModal from '@/views/bills/paymentStatusHistoryModal.vue'
import paymentErrorModal from '@/views/bills/paymentErrorModal.vue'
import paymentRecipientsModal from '@/views/bills/paymentRecipientsModal.vue'
import newOrderModal from '@/views/bills/MakeOrder/MakeBuyOrderModal.vue'
import apiClient from '@/services/axios'
import PaymentsService from '../../services/api/payments/payments.service'
import ExportCSVModal from './exportCSVModal.vue'
import { downloadFileByLink } from '../../utils'
import TextClipboard from '../../components/table/atomic/TextClipboard.vue'
import TimeStamp from '../../components/table/atomic/TimeStamp.vue'
import OrderStatus from '../../components/table/atomic/OrderStatus.vue'

const paymentsColumns = [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
  },
  // {
  //   title: 'Created',
  //   dataIndex: 'created_at',
  //   key: 'created_at',
  //   scopedSlots: { customRender: 'created_at' },
  // },
  {
    title: 'Agent',
    dataIndex: 'account',
    key: 'account',
    scopedSlots: { customRender: 'account' },
    width: '15%',
  },
  {
    title: 'Requisites',
    dataIndex: 'requisites',
    key: 'requisites',
    width: '18%',
    scopedSlots: { customRender: 'requisites' },
  },
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    scopedSlots: { customRender: 'client' },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'amount' },
    width: '13%',
  },
  {
    title: 'Time',
    dataIndex: 'agent_time_spent',
    key: 'agent_time_spent',
    width: '90px',
    // sorter: (a, b) => a.amount - b.amount,
    scopedSlots: { customRender: 'agent_time' },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '15%',
    align: 'right',
    scopedSlots: { customRender: 'status' },
  },
]

export default {
  name: 'index',
  mixins: [accessMix],
  components: {
    OrderStatus,
    TimeStamp,
    TextClipboard,
    ExportCSVModal,
    agentChatModal,
    agentAvatar,
    agentDropdown,
    webhooksModal,
    paymentStatusHistoryModal,
    paymentErrorModal,
    paymentRecipientsModal,
    newOrderModal,
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['activeProjectList']),
    /**
     * This code calculates amount of all currencies without separating, just because
     * @returns {T|number}
     */
    amountOfCurrencies() {
      if (typeof this.statData.currencies !== 'undefined') {
        return this.statData.currencies.reduce((acc, currency) => acc + currency.amount, 0)
      }
      return 0
    },
  },
  data() {
    return {
      paymentsData: [],
      paymentsColumns,
      moment,
      paymentsLoading: false,
      paymentsTableLoading: false,
      pagination: { pageSize: 20 },
      searchValue: '',
      visibility: 0,
      paymentChatModal: false,
      paymentChat: null,
      paymentWebhooksModal: false,
      paymentStatusHistoryModal: false,
      paymentErrorModal: false,
      paymentRecipientsModal: false,
      isOpenReportCSVModal: false,
      makeNewOrderModal: false,
      projectId: 0,
      accountId: 0,
      currentPaymentId: undefined,
      currentPaymentError: undefined,
      acceptedOnly: false,
      unacceptedOnly: false,
      statData: {
        count: 0,
        sum_amount: 0,
        sum_usd: 0,
      },
      datetime: [
        this.$moment(this.$moment().utc().startOf('month'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD').endOf('day'),
      ],
      reportCSVLoading: false,
    }
  },
  async mounted() {
    await this.refreshList()
    await this.getStatData()
  },
  methods: {
    getOrderStatusTitle(status, record) {
      switch (status) {
        case 'CREATED':
          return 'Awaiting agent to accept'
        case 'ACCEPTED':
          return 'Awaiting payment'
        case 'CONFIRMED':
        case 'ACTIVATED':
        case 'COMPLETED':
          return (record.card) ? 'Paid \n' + record.card.brand + ': ******' + record.card.last4 : 'Paid'
        case 'CANCELED':
          return 'Canceled' + (record.surveys && record.surveys.cancel_survey_response ? '\nReason: ' + record.surveys.cancel_survey_response.survey_option_text + '\n' + (record.surveys.cancel_survey_response.description || '') : '')
        case 'EXPIRED':
          return 'Expired'
        case 'EMPTY':
          return 'Empty'
        case 'CASHED':
          return 'Cashed'
        case 'REJECTED':
          return 'Rejected. Not confirmed!'
      }
    },
    makeNewOrder() {
      this.makeNewOrderModal = true
    },
    async createReportCSV() {
      try {
        this.reportCSVLoading = true
        const payload = this.prepareReportCSVData()
        const { data } = await PaymentsService.exportPaymentCSVFile(payload)

        downloadFileByLink(data.file_path)
      } catch (e) {
        this.$notification.error({
          message: 'System can`t make report',
          description: '',
        })
      } finally {
        this.reportCSVLoading = false
      }
    },
    prepareReportCSVData() {
      const payload = {
        agent_ids: this.accountId !== 0 ? [this.accountId] : null,
        project_ids: this.projectId !== 0 ? [this.projectId] : null,
        order_types: this.visibility !== 0 ? [this.visibility] : null,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
        search: this.searchValue.trim().length ? this.searchValue : null,
      }

      return Object.fromEntries(Object.entries(payload).filter(([_, value]) => value !== null))
    },
    openCustomReportCSVModal() {
      this.isOpenReportCSVModal = true
    },
    closeCustomReportCSVModal() {
      this.isOpenReportCSVModal = false
    },
    async getStatData() {
      try {
        const url = '/admin/payments/analytics/digits'
        const response = await apiClient.get(url, {
          params: {
            agent_id: this.accountId,
            project_id: this.projectId,
            from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
            to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
            search: this.searchValue,
          },
        })
        this.statData = response.data.data
      } catch (e) {
      }
    },
    handleDatesChange(open) {
      if (!open) {
        this.pagination.current = 1
        this.refreshList()
      }
    },
    handleAgentChange(open) {
      if (!open) {
        this.pagination.current = 1
        this.refreshList()
      }
    },
    updateTable() {
      this.pagination.current = 1
      this.refreshList()
      this.getStatData()
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        ...filters,
        search: this.searchValue,
        agent_id: this.accountId,
        project_id: this.projectId,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
      })
    },
    async fetch(params = {}) {
      // console.log('params:', params)
      this.paymentsTableLoading = true
      const reqData = {
        results: 20,
        visibility: this.visibility,
        ...params,
      }
      return PaymentsService.getClientPayments(
        reqData,
      ).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.paymentsTableLoading = false
        this.paymentsData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.paymentsTableLoading = false
      })
    },
    refreshList() {
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        search: this.searchValue,
        project_id: this.projectId,
        agent_id: this.accountId,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
      })
    },
    handleSearchChanged() {
      this.searchValue = ''
    },
    async searchByInput(value) {
      this.pagination.current = 1
      await this.refreshList()
      await this.getStatData()
    },
    async confirmPayment(paymentId) {
      // ----
      return PaymentsService.confirmClientPayment(paymentId).then((response) => {
        const tData = response.data.data
        const index = this.paymentsData.findIndex(obj => obj.id === tData.id)
        this.paymentsData[index] = tData
        this.$message.success(`Payment #${paymentId} confirmed`)
        this.refreshList()
      }).catch(error => {
        this.$message.error(`Error confirm payment. Message: ${error.message}`)
      })
    },
    openChatModal(chatObj) {
      this.paymentChat = chatObj
      this.paymentChatModal = true
    },
    showWebhooks(paymentId) {
      this.currentPaymentId = paymentId
      this.paymentWebhooksModal = true
    },
    showStatusHistory(paymentId) {
      this.currentPaymentId = paymentId
      this.paymentStatusHistoryModal = true
    },
    showPaymentError(paymentId, error) {
      this.currentPaymentId = paymentId
      this.currentPaymentError = error
      this.paymentErrorModal = true
    },
    showPaymentRecipients(paymentId) {
      this.currentPaymentId = paymentId
      this.paymentRecipientsModal = true
    },
    clipboardHandle(v) {
      // console.log(v)
      this.$clipboard(v)
      this.$notification.success({
        message: this.$t('messages.copiedToClipboard'),
        description: `${v.slice(0, 16)}..`,
      })
    },
  },
}
</script>
<style>
.promocode-loading {
  display: none;
}
</style>
<style scoped>

</style>
